import { lazy } from "react";
const DashboardPage = lazy(() => import("./pages/dashboard"));
const StartingBalance = lazy(() => import("./pages/bookkeping/startingBalance"));
const CoaCategory = lazy(() => import("./pages/master/coacategory"));
const CoaPage = lazy(() => import("./pages/master/coa"));
const CreateCoaPage = lazy(() => import("./pages/master/coa/create"));
const EditCoaPage = lazy(() => import("./pages/master/coa/edit"));
const DownloadCoaPage = lazy(() => import("./pages/master/coa/download"));
const UploadCoaPage = lazy(() => import("./pages/master/coa/upload"));
const SettingJournalNumber = lazy(() => import("./pages/settings/journalNumber"));
const SettinigProfitLoss = lazy(() => import("./pages/settings/profitLoss"));
const SettingNetProfitLoss = lazy(() => import("./pages/settings/netProfitLoss"));
const SettingBalanceSheet = lazy(() => import("./pages/settings/balanceSheet"));
const SettingCashFlow = lazy(() => import("./pages/settings/cashFlow"));
const SettingFilterAccount = lazy(() => import("./pages/settings/filterAccount"));
const SettingRoles = lazy(() => import("./pages/settings/roles"));
const SettingCreateRole = lazy(() => import("./pages/settings/roles/create"));
const SettingUpdateRole = lazy(() => import("./pages/settings/roles/edit"));
const SettingUser = lazy(() => import("./pages/settings/user"));
const SettingCreateUser = lazy(() => import("./pages/settings/user/create"));
const SettingUpdateUser = lazy(() => import("./pages/settings/user/edit"));
const ReportLedger = lazy(() => import("./pages/report/ledger"));
const ReportProfitLoss = lazy(() => import("./pages/report/profitLoss"));
const ReportBalanceSheet = lazy(() => import("./pages/report/balanceSheet"));
const ReportCashFlow = lazy(() => import("./pages/report/cashFlow"));
const Migration = lazy(() => import("./pages/bookkeping/migration"));
const ViewMigration = lazy(() => import("./pages/bookkeping/migration/view"));
const UploadMigration = lazy(() => import("./pages/bookkeping/migration/upload"));
const EditMigration = lazy(() => import("./pages/bookkeping/migration/edit"));
const Ledger = lazy(() => import("./pages/bookkeping/ledger"));
const ViewLedger = lazy(() => import("./pages/bookkeping/ledger/view"));
const AddLedger = lazy(() => import("./pages/bookkeping/ledger/create"));
const EditLedger = lazy(() => import("./pages/bookkeping/ledger/edit"));
const Expense = lazy(() => import("./pages/bookkeping/expense"));
const ViewExpense = lazy(() => import("./pages/bookkeping/expense/view"));
const AddExpense = lazy(() => import("./pages/bookkeping/expense/create"));
const EditExpense = lazy(() => import("./pages/bookkeping/expense/edit"));
const Revenue = lazy(() => import("./pages/bookkeping/revenue"));
const ViewRevenue = lazy(() => import("./pages/bookkeping/revenue/view"));
const AddRevenue = lazy(() => import("./pages/bookkeping/revenue/create"));
const EditRevenue = lazy(() => import("./pages/bookkeping/revenue/edit"));
const BudgetActivity = lazy(() => import("./pages/budgeting/activity"));
const AddBudgetActivity = lazy(() => import("./pages/budgeting/activity/create"));
const ViewBudgetActivity = lazy(() => import("./pages/budgeting/activity/view"));
const EditBudgetActivity = lazy(() => import("./pages/budgeting/activity/edit"));
const SettingBudgetNumber = lazy(() => import("./pages/settings/budgetNumber"));

const EmployeeList = lazy(() => import("./pages/employee/list"))
const EmployeeFrom = lazy(() => import("./pages/employee/form"))
const EmployeeUploadForm = lazy(() => import("./pages/employee/upload_form"))

const Payroll = lazy(() => import("./pages/payroll/"))
const PayrollFrom = lazy(() => import("./pages/payroll/form"))
const PayrollSlipGaji = lazy(() => import("./pages/payroll/slip_gaji/view/SlipGajiView"))

const MasterPosition = lazy(() => import("./pages/master/position"))
const MasterReduction = lazy(() => import("./pages/master/reduction"))
const MasterReductionForm = lazy(() => import("./pages/master/reduction/form"))
const MasterTunjangan = lazy(() => import("./pages/master/tunjangan"))
const MasterTunjanganForm = lazy(() => import("./pages/master/tunjangan/form"))
const MasterSignature = lazy(() => import("./pages/master/signature"))
const MasterUnit = lazy(() => import("./pages/master/unit"))
const MasterProfileParams = lazy(() => import("./pages/master/profile_params"))

export default function PageRouteList() {
  const RouteList = [
    {
      path: "/",
      label: "Data Pegawai",
      element: <EmployeeList />,
    },
    {
      path: "/beranda",
      label: "Beranda",
      element: <DashboardPage />,
    },
    {
      path: "/pembukuan",
      label: "Pembukuan",
      element: <DashboardPage />,
    },
    {
      path: "/pembukuan/saldo-awal",
      label: "Saldo Awal",
      element: <StartingBalance />,
    },
    {
      path: "/pembukuan/migrasi",
      label: "Daftar Migrasi",
      element: <Migration />,
    },
    {
      path: "/pembukuan/migrasi/lihat",
      label: "Rincian Data Migrasi",
      element: <ViewMigration />,
    },
    {
      path: "/pembukuan/migrasi/edit",
      label: "Edit Data Migrasi",
      element: <EditMigration />,
    },
    {
      path: "/pembukuan/migrasi/unggah",
      label: "Unggah Data Migrasi",
      element: <UploadMigration />,
    },
    {
      path: "/pembukuan/jurnal-umum",
      label: "Daftar Jurnal Umum",
      element: <Ledger />,
    },
    {
      path: "/pembukuan/jurnal-umum/lihat",
      label: "Rincian Jurnal Umum",
      element: <ViewLedger />,
    },
    {
      path: "/pembukuan/jurnal-umum/tambah",
      label: "Buat Jurnal Umum",
      element: <AddLedger />,
    },
    {
      path: "/pembukuan/jurnal-umum/edit",
      label: "Edit Jurnal Umum",
      element: <EditLedger />,
    },
    {
      path: "/pembukuan/pengeluaran",
      label: "Daftar Pengeluaran",
      element: <Expense />,
    },
    {
      path: "/pembukuan/pengeluaran/lihat",
      label: "Rincian Pengeluaran",
      element: <ViewExpense />,
    },
    {
      path: "/pembukuan/pengeluaran/tambah",
      label: "Buat Pengeluaran",
      element: <AddExpense />,
    },
    {
      path: "/pembukuan/pengeluaran/edit",
      label: "Edit Pengeluaran",
      element: <EditExpense />,
    },
    {
      path: "/pembukuan/Pendapatan",
      label: "Daftar Pendapatan",
      element: <Revenue />,
    },
    {
      path: "/pembukuan/pendapatan/lihat",
      label: "Rincian Pendapatan",
      element: <ViewRevenue />,
    },
    {
      path: "/pembukuan/pendapatan/tambah",
      label: "Buat Pendapatan",
      element: <AddRevenue />,
    },
    {
      path: "/pembukuan/pendapatan/edit",
      label: "Edit Pendapatan",
      element: <EditRevenue />,
    },
    {
      path: "/anggaran",
      label: "Anggaran",
      element: <DashboardPage />,
    },
    {
      path: "/anggaran/kegiatan",
      label: "Anggaran Berbasis Aktivitas",
      element: <BudgetActivity />,
    },
    {
      path: "/anggaran/kegiatan/tambah",
      label: "Tambah Anggaran Aktivitas",
      element: <AddBudgetActivity />,
    },
    {
      path: "/anggaran/kegiatan/edit",
      label: "Edit Anggaran Aktivitas",
      element: <EditBudgetActivity />,
    },
    {
      path: "/anggaran/kegiatan/lihat",
      label: "Rincian Anggaran Aktivitas",
      element: <ViewBudgetActivity />,
    },
    {
      path: "/master",
      label: "Master",
      element: <DashboardPage />,
    },
    {
      path: "/master/kategori-coa",
      label: "Kategori COA",
      element: <CoaCategory />,
    },
    {
      path: "/master/coa",
      label: "Daftar COA",
      element: <CoaPage />,
    },
    {
      path: "/master/coa/tambah",
      label: "Tambah Akun",
      element: <CreateCoaPage />,
    },
    {
      path: "/master/coa/edit",
      label: "Edit Akun",
      element: <EditCoaPage />,
    },
    {
      path: "/master/coa/upload",
      label: "Upload Coa",
      element: <UploadCoaPage />,
    },
    {
      path: "/master/coa/download",
      label: "Export Coa",
      element: <DownloadCoaPage />,
    },
    {
      path: "/pengaturan/nomor-jurnal",
      label: "Pengaturan Nomor Jurnal",
      element: <SettingJournalNumber />,
    },
    {
      path: "/pengaturan/laba-rugi",
      label: "Pengaturan Laba Rugi",
      element: <SettinigProfitLoss />,
    },
    {
      path: "/pengaturan/laba-rugi-bersih",
      label: "Pengaturan Laba / Rugi Bersih",
      element: <SettingNetProfitLoss />,
    },
    {
      path: "/pengaturan/neraca-keuangan",
      label: "Pengaturan Neraca Keuangan",
      element: <SettingBalanceSheet />,
    },
    {
      path: "/pengaturan/hak-akses",
      label: "Pengaturan Hak Akses",
      element: <SettingRoles />,
    },
    {
      path: "/pengaturan/hak-akses/tambah",
      label: "Tambah Hak Akses",
      element: <SettingCreateRole />,
    },
    {
      path: "/pengaturan/hak-akses/edit",
      label: "Edit Hak Akses",
      element: <SettingUpdateRole />,
    },
    {
      path: "/pengaturan/pengguna",
      label: "Pengaturan Pengguna",
      element: <SettingUser />,
    },
    {
      path: "/pengaturan/pengguna/tambah",
      label: "Tambah Pengguna",
      element: <SettingCreateUser />,
    },
    {
      path: "/pengaturan/pengguna/edit",
      label: "Edit Pengguna",
      element: <SettingUpdateUser />,
    },
    {
      path: "/pengaturan/arus-kas",
      label: "Pengaturan Arus Kas",
      element: <SettingCashFlow />,
    },
    {
      path: "/pengaturan/filter-akun",
      label: "Pengaturan Filter Akun",
      element: <SettingFilterAccount />,
    },
    {
      path: "/pengaturan/nomor-anggaran",
      label: "Pengaturan Nomor Anggaran",
      element: <SettingBudgetNumber />,
    },
    {
      path: "/laporan/buku-besar",
      label: "Laporan Buku Besar",
      element: <ReportLedger />,
    },
    {
      path: "/laporan/laba-rugi",
      label: "Laporan Laba Rugi",
      element: <ReportProfitLoss />,
    },
    {
      path: "/laporan/neraca-keuangan",
      label: "Laporan Neraca Keuangan",
      element: <ReportBalanceSheet />,
    },
    {
      path: "/laporan/arus-kas",
      label: "Laporan Arus Kas",
      element: <ReportCashFlow />,
    },
    {
      path: "/data-pegawai",
      label: "Data Pegawai",
      element: <EmployeeList />,
    },
    {
      path: "/data-pegawai/tambah",
      label: "Tambah Data Pegawai",
      element: <EmployeeFrom type='add' />,
    },
    {
      path: "/data-pegawai/ubah",
      label: "Ubah Data Pegawai",
      element: <EmployeeFrom type='edit' />,
    },
    {
      path: "/data-pegawai/upload",
      label: "Unggah Data Pegawai",
      element: <EmployeeUploadForm />,
    },
    {
      path: "/master/jabatan",
      label: "Jabatan",
      element: <MasterPosition />,
    },
    {
      path: "/master/tanda_tangan",
      label: "Tanda Tangan",
      element: <MasterSignature />,
    },
    {
      path: "/master/potongan",
      label: "Potongan",
      element: <MasterReduction />,
    },
    {
      path: "/master/potongan/tambah",
      label: "Tambah Potongan",
      element: <MasterReductionForm type='add' />,
    },
    {
      path: "/master/potongan/ubah",
      label: "Ubah Potongan",
      element: <MasterReductionForm type='edit' />,
    },
    {
      path: "/master/tunjangan",
      label: "Tunjangan",
      element: <MasterTunjangan />,
    },
    {
      path: "/master/tunjangan/tambah",
      label: "Tambah Tunjangan",
      element: <MasterTunjanganForm type='add' />,
    },
    {
      path: "/master/tunjangan/ubah",
      label: "Ubah Tunjangan",
      element: <MasterTunjanganForm type='edit' />,
    },
    {
      path: "/master/unit",
      label: "Unit",
      element: <MasterUnit />,
    },
    {
      path: "/master/profile_params",
      label: "Profile Param",
      element: <MasterProfileParams />,
    },
    {
      path: "/data_payroll",
      label: "Payroll",
      element: <Payroll />,
    },
    {
      path: "/data_payroll/tambah",
      label: "Tambah Payroll",
      element: <PayrollFrom />,
    },
    {
      path: "/data_payroll/ubah",
      label: "Ubah Payroll",
      element: <PayrollFrom />,
    },
    {
      path: "/data_payroll/view",
      label: "Payroll",
      element: <PayrollFrom />,
    },
    {
      path: "/data_payroll/slip-gaji",
      label: "Slip Gaji",
      element: <PayrollSlipGaji />,
    }
  ];
  return { RouteList };
}
